<template>
    <div class="info-content">
        <div class="head-con">
            <h3>个人资料</h3>
        </div>
        <el-dialog
            v-model="dialogVisible"
            title="设置头像"
            width="30%"
        >
            <el-upload
                class="avatar-uploader"
                action="https://admin.iseso.net//index.php?store_id=1&store_type=6&module=app_pc&action=product&m=uploadImgs"
                method="post"
                :on-success="setHeadImg"
            >
                <el-icon class="avatar-uploader-icon">
                    <plus />
                </el-icon>
                <p>支持JPEG/BMP/PNG</p>
            </el-upload>

            <template #footer>
                <div class="foot-con">
                    <el-button @click="cancelUpload">取消</el-button>
                    <el-button type="danger" @click="toSaveImg">保存</el-button>
                </div>
            </template>
        </el-dialog>
        <div class="content">
            <div class="item">
                <p>用户头像</p>
                <div class="edit-con">
                    <img :src="headimgurl" class="headimg"/>
                    <span @click="editHead">编辑头像</span>
                </div>
            </div>
            <div class="item">
                <p style="letter-spacing:15px;">昵称</p>
                <!-- <span>{{user_name}}</span> -->
                <el-input v-model="user_name" style="width:160px;"/>
            </div>
            <div class="item">
                <p style="letter-spacing:15px;">性别</p>
                <el-radio v-model="sex" label="1">男</el-radio>
                <el-radio v-model="sex" label="2">女</el-radio>
            </div>

            <div class="item">
                <p>手机号码</p>
                <div class="remark-con">
                    <span style="margin-right:10px;">{{mobile}}</span>
                    <p>（注：修改手机号码请到<i class="toseruity">账号安全</i>）</p>
                </div>
            </div>
            <div class="item">
                <p>出生日期</p>
                <el-date-picker v-model="birthday" type="date" placeholder="Pick a day">
                </el-date-picker>
            </div>
        </div>

        <div class="info-foot">
            <el-button type="danger" @click="toCommit">保存</el-button>
        </div>
    </div>
</template>
<script>
import { onMounted,reactive,ref,toRefs } from 'vue'
import { getData } from '@/utils/req'
import { ElMessage } from 'element-plus'
import { Plus } from '@element-plus/icons'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
    components:{
        Plus
    },
    setup(){
        const router = useRouter()
        const store = useStore()
        let pc_user = ref('')
        let state = reactive({
            birthday:'',
            headimgurl:'',
            sex:'',
            user_name:''
        })
        let mobile = ref('')
        let dialogVisible = ref(false)
        onMounted(()=>{
            pc_user.value = JSON.parse(localStorage.getItem('pc_user'))
            axios()
        })
        const editHead =()=>{
            dialogVisible.value = true
        }
        const setHeadImg = (e)=>{
            state.headimgurl = e.data;
        }
        const axios = ()=>{
            const data= {
                module: 'app_pc',
                action: 'user',
                m: 'personal_resources',
                access_id:pc_user.value.access_id
            }
            getData(data).then(res=>{
                state.birthday = res.data.birthday
                state.headimgurl = res.data.headimgurl
                mobile.value = res.data.mobile
                state.sex = res.data.sex
                state.user_name = res.data.user_name
            })
        }
        const toseruity =()=>{
            console.log(1)
            router.push({
                path:'/security'
            })
        }
        const cancelUpload =()=>{
            dialogVisible.value = false
        }
        const toSaveImg = ()=>{
            ElMessage.success({
                message:'保存成功',
                duration:1500
            })
            dialogVisible.value = false
        }
        const toCommit =()=>{
            let data ={
                module: 'app_pc',
                action: 'user',
                m: 'set_personal_resources',
                access_id: pc_user.value.access_id,
                headimgurl:state.headimgurl,
                user_name:state.user_name,
                birthday:state.birthday,
                sex:state.sex
            }
            getData(data).then(res=>{
                if(res.code == 200){
                    ElMessage.success({
                        message:res.message,
                        duration:1500
                    })
                    axios();
                    store.dispatch("updateCart")
                }
                else{
                    ElMessage.error({
                        message:res.message,
                        duration:1500
                    })
                }
            })
        }
        return {
            ...toRefs(state),
            dialogVisible,
            mobile,
            editHead,
            cancelUpload,
            toSaveImg,
            toCommit,
            toseruity,
            setHeadImg
        }
    }
}
</script>
<style scoped>
    .info-content{
        background: #fff;
        width: 100%;
        height: 100%;
        position: relative;
    }
    .info-content .head-con{
        width: 100%;
        height: 56px;
        border-bottom: 1px solid #E6E6E6;
    }
    .info-content .head-con h3{
        color: #020202;
        font-size: 16px;
        font-weight: bold;
        padding-left: 16px;
        line-height: 56px;
    }
    .content{
        margin-top: 30px;
    }
    .content .item{
        display: flex;
        align-items: center;
        margin-left: 30px;
        margin-bottom: 20px;
    }
    .edit-con{
        position: relative;
    }
    .edit-con span{
        position: absolute;
        bottom: 4px;
        left: 0;
        right: 0;
        height: 20px;
        background: rgba(0,0,0,0.5);
        color: #FFFFFF;
        font-size: 12px;
        text-align: center;
        line-height: 20px;
        cursor: pointer;
        display: none;
    }
    .edit-con:hover span{
        display: block;
    }
    .content .item .headimg{
        width: 68px;
        height: 68px;
    }
    .content .item p{
        padding-right: 20px;
        font-size: 14px;
        color: #666666;
    }
    .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        text-align: center;
        border:1px dashed #333;
    }
    .avatar-uploader-icon svg {
        margin-top: 74px; /* (178px - 28px) / 2 - 1px */
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    ::v-deep .avatar-uploader{
        text-align: center;
    }
    ::v-deep .el-dialog__footer{
        height: 68px;
        background: #f6f6f6;
    }
    ::v-deep .el-dialog__header{
        border-bottom: 1px solid #e6e6e6;
    }
    .foot-con .el-button{
        width: 112px;
        height: 36px;
        border-radius: 0px;
    }
    .el-button:active{
        background: #E6E6E6;
    }
    .remark-con{
        display: flex;
        align-items: center;
    }
    .info-foot{
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 81px;
        border-top: 1px solid #E6E6E6;
        text-align: right;
    }
    .info-foot .el-button{
        width: 112px;
        height: 36px;
        border-radius: 0px;
        margin: 20px;
    }
</style>